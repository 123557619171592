import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const Footer = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <footer className="section-top-80 relative bg-neutral-black lg:pb-[60px]">
      <div className="section">
        {/* <div className="flex-grow container mx-auto px-4 py-8">
          <section className="bg-white-500 rounded-lg p-8 mb-12 text-black">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Explore Creatify's Enterprise plan</h1>
            <p className="text-lg mb-6">
              Our experienced video designers, enhanced by AI tools, are dedicated to creating a wide range of short video ads
              customized for your brand's requirements. Enjoy unlimited video ad requests at a fixed monthly rate.
            </p>
            <button className="bg-white text-indigo-600 font-semibold py-2 px-4 rounded">
              Let's talk ↗
            </button>
          </section>
        </div> */}
        <div className="mx-auto grid max-w-[1320px] gap-20 bg-neutral-dark-grey py-10 px-[15px] md:grid-cols-3 md:gap-10 lg:rounded-[20px] lg:p-[60px]">
          <div className="md:col-span-2">
            <div className="gap-6 md:col-span-2 flex justify-between flex-wrap">
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.page')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  {
                    currentLanguage == 'en' &&
                    <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                      href='https://decorify.pro/'
                    >
                      {t('footer.blog')}
                    </a>
                  }
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" to="/app">
                    {t('footer.app')}
                  </Link>
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                    to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  >
                    {t('footer.signin')}
                  </Link>
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                    to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}
                  >
                    {t('footer.signup')}
                  </Link>
                  {/* <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    href={currentLanguage === 'en' ? '/how-to-get-ai-yearbook-for-free' : `/${currentLanguage}/how-to-get-ai-yearbook-for-free`}
                  >
                    {t('footer.yearbook')}
                  </a> */}
                  {currentLanguage !== 'vi' &&
                    <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" href="https://decorify.lemonsqueezy.com/affiliates">
                      {t('footer.affiliate')}
                    </a>
                  }
                </div>
              </div>
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.tos')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                    href={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                  >
                    {t('footer.terms')}
                  </a>
                  <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                    href={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                  >
                    {t('footer.privacy')}
                  </a>
                  <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                    href={currentLanguage === 'en' ? '/refund' : `/${currentLanguage}/refund`}
                  >
                    {t('refund.title')}
                  </a>
                </div>
              </div>
              {/* <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.contact')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  <a href="mailto:support@decorify.pro?subject=Hello" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.email')}
                  </a>
                  <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" href="#" onClick={() => {window.HubSpotConversations.widget.open();}}>
                    {t('footer.chatNow')}
                  </a>
                </div>
              </div> */}
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.other')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  <a href="mailto:support@decorify.pro?subject=Feedback" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.sendFeedback')}
                  </a>
                  {/* <a href="mailto:support@decorify.pro?subject=Report bugs" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.reportBug')}
                  </a>
                  <a href="mailto:support@decorify.pro?subject=Request features" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.require')}
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between text-white md:order-first">
            <div>
              <Link to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`} aria-label="Reok Logo">
                <img src="/images/airoomdecor-full.webp" width={160}></img>
              </Link>
              <div className="t-14 mt-4 max-w-[290px] font-body text-white/80">
                <p>{t('footer.title')}</p>
                {/* <LanguageSwitcher/> */}
              </div>
              <div className="mt-10 text-neutral-light-grey">
                <nav className="flex gap-3">
                  <a
                    target="_blank"
                    className="svg-icon link-dark"
                    href="https://www.tiktok.com/@decorifypro"
                    aria-label="Decorify Tiktok"
                  >
                    <svg fill="#000000" width={21} height={21} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                      <path d="m 4.9520184,12.936803 c -1.12784,-0.2039 -2.19411,-0.9875 -2.66789,-1.9606 -0.32895,-0.6757 -0.45541,-1.3901003 -0.37418,-2.1137003 0.15054,-1.3412 0.84482,-2.4395 1.92406,-3.0439 0.56899,-0.3186 1.38421,-0.4769 1.991,-0.3867 l 0.35091,0.052 9e-5,1.0725 9e-5,1.0725 -0.332,-0.014 c -0.79998,-0.033 -1.39595,0.3096 -1.70379,0.9784 -0.1473,0.32 -0.18461,0.8887 -0.081,1.2351 0.12773,0.4273003 0.50542,0.8132003 0.96145,0.9825003 0.15535,0.058 0.32344,0.08 0.61152,0.079 0.35862,-4e-4 0.42448,-0.013 0.67663,-0.1323 0.36505,-0.1726 0.63141,-0.4231 0.78797,-0.7411 0.10147,-0.2061003 0.13414,-0.3430003 0.16587,-0.6951003 0.0217,-0.2412 0.0401,-2.2122 0.0409,-4.38 l 10e-4,-3.94149998 0.68371,0 c 0.37605,0 0.8277,0.012 1.00368,0.027 l 0.31995,0.027 0,0.1584 c 0,0.3813 0.22299,1.1127 0.45156,1.4812 0.0571,0.092 0.2564996,0.3178 0.4431796,0.5018 0.36068,0.3555 0.66494,0.5352 1.13352,0.6692 0.138,0.04 0.28359,0.089 0.32353,0.109 0.0399,0.02 0.11522,0.038 0.16728,0.038 0.0521,4e-4 0.13701,0.012 0.18876,0.026 l 0.0941,0.025 0,0.9948 0,0.9948 -0.17773,-0.019 c -0.9611,-0.1037 -1.72925,-0.3601 -2.3423096,-0.782 -0.30468,-0.2096 -0.33102,-0.222 -0.30218,-0.1422 0.0104,0.029 0.003,1.1249 -0.0164,2.436 -0.0336,2.2728 -0.0396,2.3992 -0.12781,2.7173003 -0.33904,1.2222 -1.09994,2.1297 -2.10183,2.5068 -0.6126,0.2306 -1.39679,0.2932 -2.09405,0.1671 z" />
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" aria-label="Decorify Twitter" className="svg-icon link-dark" href="https://x.com/decorifyai">
                    <svg width="18" height="18.014" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18.014">
                      <path d="M10.714 7.628 17.415 0h-1.588l-5.822 6.623L5.36 0H0l7.029 10.017L0 18.014h1.588l6.144 -6.995 4.908 6.995H18M2.161 1.173h2.439l11.227 15.728h-2.439" fill="fff" />
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" aria-label="Decorify Facebook" className="svg-icon link-dark" href="https://www.facebook.com/decorifypro">
                    <svg fill="#000000" width="22px" height="22px" viewBox="-0.175 -0.05 0.6 0.6" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-facebook">
                      <path d="M0.051 0.097v0.069H0.001v0.084h0.05v0.25H0.154V0.249h0.069s0.007 -0.04 0.01 -0.084H0.155V0.108c0 -0.009 0.011 -0.02 0.022 -0.02h0.056V0H0.157c-0.108 0 -0.106 0.084 -0.106 0.097z" />
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" aria-label="Decorify Youtube" className="svg-icon link-dark" href="https://www.youtube.com/@decorifypro">
                    <svg fill="#000000" width="22px" height="22px" viewBox="-0.055 -0.138 0.66 0.66" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-youtube">
                      <path d="M0.435 0H0.114C0.051 0 0 0.051 0 0.113v0.159c0 0.062 0.051 0.113 0.114 0.113h0.321c0.063 0 0.114 -0.05 0.114 -0.113V0.113c0 -0.062 -0.051 -0.113 -0.114 -0.113zM0.358 0.2 0.208 0.271a0.006 0.006 0 0 1 -0.009 -0.005V0.12a0.006 0.006 0 0 1 0.009 -0.005l0.15 0.075a0.006 0.006 0 0 1 0 0.011z" />
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" className="svg-icon link-dark" href="https://www.linkedin.com/company/decorify-pro">
                    <svg fill="#000000" width="22px" height="22px" viewBox="0 0 0.66 0.66" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                      <path d="M0.563 0.055H0.097a0.04 0.04 0 0 0 -0.04 0.039v0.471A0.04 0.04 0 0 0 0.097 0.605h0.466a0.04 0.04 0 0 0 0.04 -0.039V0.094A0.04 0.04 0 0 0 0.563 0.055ZM0.222 0.515h-0.083v-0.247h0.083ZM0.181 0.233a0.043 0.043 0 1 1 0 -0.086 0.043 0.043 0 1 1 0 0.086Zm0.339 0.282h-0.083v-0.133c0 -0.033 -0.012 -0.055 -0.042 -0.055A0.045 0.045 0 0 0 0.353 0.357a0.055 0.055 0 0 0 -0.003 0.02v0.138h-0.083v-0.247h0.083V0.302a0.083 0.083 0 0 1 0.075 -0.041c0.055 0 0.095 0.035 0.095 0.112Z" />
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" aria-label="Decorify Instagram" className="svg-icon link-dark" href="https://www.instagram.com/decorify.pro">
                    <svg fill="#000000" width="22px" height="22px" viewBox="-0.05 -0.05 0.6 0.6" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-instagram">
                      <path d="M0.35 0h-0.202A0.149 0.149 0 0 0 0 0.149v0.202a0.149 0.149 0 0 0 0.149 0.149h0.202a0.149 0.149 0 0 0 0.149 -0.149v-0.202A0.149 0.149 0 0 0 0.35 0zm0.099 0.35a0.099 0.099 0 0 1 -0.099 0.099h-0.202a0.099 0.099 0 0 1 -0.098 -0.099v-0.202a0.099 0.099 0 0 1 0.099 -0.098h0.202a0.099 0.099 0 0 1 0.098 0.099v0.202z" />
                      <path d="M0.25 0.12A0.129 0.129 0 0 0 0.121 0.25a0.129 0.129 0 0 0 0.129 0.129 0.129 0.129 0 0 0 0.129 -0.129A0.129 0.129 0 0 0 0.25 0.121zm0 0.208a0.079 0.079 0 1 1 0 -0.158 0.079 0.079 0 0 1 0 0.158z" />
                      <path cx="15.156" cy="4.858" r="1.237" d="M0.41 0.121A0.031 0.031 0 0 1 0.379 0.152A0.031 0.031 0 0 1 0.348 0.121A0.031 0.031 0 0 1 0.41 0.121z" />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
            <p className="t-14 mt-6 text-neutral-light-grey">
              © 2025 Decorify.pro, All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
